import React from "react";
import './BlogPostItem.scss';
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link} from "react-router-dom";


const BlogPostItem = ({size, noImg, data}) => {
    return (<div className={classNames('blog-post-item', 'blog-post-item--' + size, {'blog-post-item--no-img': noImg})}>
        {!noImg &&
        <div className="blog-post-item__image">
            <img src={'https://barwise.app/p/uploads/' + data?.resource_image} alt={data?.resource_name}/>
        </div>}
        <div className="blog-post-item__title">{data?.resource_name}</div>
        <div className="blog-post-item__text">
            {data?.resource_list_content}
        </div>
        <Link to={'/resources/'+ data?.resource_category_slug+'/' + data?.resource_slug}>
            Read the article
        </Link>
    </div>);
}
BlogPostItem.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    noImg: PropTypes.bool
};
BlogPostItem.defaultProps = {
    size: "medium",
    noImg: false
};
export default BlogPostItem
