import React from "react";
import './MobileMenu.scss';
import {Icon} from "../index";
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/actions";
import classNames from "classnames";

const MobileMenu = () => {
    const dispatch = useDispatch();

    const {mobileMenu} = useSelector(state => state.Api);

    const closeMenu = () => {
        dispatch({type: actions.SET_MOBILE_MENU, payload: false})
    }

    return (<div className={classNames('mobile-menu', {'mobile-menu--visible': mobileMenu})}>
        <div className="mobile-menu__close" onClick={closeMenu}>
            <Icon icon="close"/>
        </div>
        <div className="mobile-menu__content">
            <div className="mobile-menu__items">
                <NavLink to="/" onClick={closeMenu}>Discover BarWise</NavLink>
                <NavLink to="/resources" onClick={closeMenu}>Resources</NavLink>
                <div className="split"/>
                <NavLink to="/about" className="small" onClick={closeMenu}>About BarWise</NavLink>
                <NavLink to="/contact" className="small" onClick={closeMenu}>Contact</NavLink>
            </div>
            <div className="mobile-menu__socials">
                <a href="https://www.facebook.com/profile.php?id=100090173954801" target="_blank">
                    <Icon icon="facebook"/>
                </a>
                <a href="https://www.instagram.com/BarWiseLLC/" target="_blank">
                    <Icon icon="instagram"/>
                </a>
                <a href="https://twitter.com/BarwiseLlc" target="_blank">
                    <Icon icon="twitter"/>
                </a>
            </div>

            <div className="mobile-menu__links">
                <Link to="/privacy-policy">
                    Privacy Policy
                </Link>
                <Link to="/terms-of-use">
                    Terms of Use
                </Link>
            </div>
            <div className="mobile-menu__copyright">
                BarWise © 2021 All rights reserved.
            </div>
        </div>
    </div>);
}

export default MobileMenu
