import React, {useEffect} from "react";
import {Footer, BlogPostItem, Icon} from "../../components";
import './SearchList.scss';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import actions from "../../redux/actions";


const SearchList = () => {

    const dispatch = useDispatch();
    const {searchResult} = useSelector(state => state.Api);
    const {keyword} = useParams();

    useEffect(() => {
        dispatch({type: actions.GET_SEARCH, payload: {keyword: keyword}})
    }, [keyword])

    return (
        <div className="animate__animated animate__fadeIn">
            <div className="search-list">
                <div className="search-list__title">
                    <div className="container">
                        <div className="search-list__title__top">
                            <Icon icon="right-arrow"/> <span>Search Results for: </span>
                        </div>
                        <b>{keyword}</b>
                    </div>
                </div>
                <div className="container">
                    {searchResult && searchResult.map((item, index) => {
                        return <BlogPostItem noImg={true} data={item} key={index}/>
                    })}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default SearchList;
