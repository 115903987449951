import React, {useEffect} from "react";
import {Footer, BlogCategories, BlogPostItem, Pagination} from "../../components";
import './BlogList.scss';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import actions from "../../redux/actions";


const BlogList = () => {

    const dispatch = useDispatch();
    const {resources} = useSelector(state => state.Api);
    const {slug} = useParams();

    useEffect(() => {
        getData('1');
    }, [slug])

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, [resources])

    const getData = (page) => {
        dispatch({type: actions.GET_RESOURCES, payload: {slug: slug, page: page}})
    }

    return (
        <div className="animate__animated animate__fadeIn">
            <BlogCategories/>
            <div className="blog-list ">
                <div className="container">
                    {(resources?.data?.[0] || resources?.data?.[1] || resources?.data?.[2]) &&
                    <div className="blog-list__row animate__animated animate__fadeIn">
                        {resources?.data?.[0] && <BlogPostItem size="large" data={resources?.data?.[0]}/>}
                        {resources?.data?.[1] && <BlogPostItem size="small" data={resources?.data?.[1]}/>}
                        {resources?.data?.[2] && <BlogPostItem size="medium" data={resources?.data?.[2]}/>}
                    </div>}
                    {(resources?.data?.[3] || resources?.data?.[4] || resources?.data?.[5]) &&
                    <div className="blog-list__row animate__animated animate__fadeIn">
                        {resources?.data?.[3] && <BlogPostItem size="small" data={resources?.data?.[3]}/>}
                        {resources?.data?.[4] && <BlogPostItem size="medium" data={resources?.data?.[4]}/>}
                        {resources?.data?.[5] && <BlogPostItem size="large" data={resources?.data?.[5]}/>}
                    </div>}
                    {(resources?.data?.[6] || resources?.data?.[7] || resources?.data?.[8]) &&
                    <div className="blog-list__row animate__animated animate__fadeIn">
                        {resources?.data?.[6] && <BlogPostItem size="large" data={resources?.data?.[6]}/>}
                        {resources?.data?.[7] && <BlogPostItem size="small" data={resources?.data?.[7]}/>}
                        {resources?.data?.[8] && <BlogPostItem size="medium" data={resources?.data?.[8]}/>}
                    </div>}
                </div>
                <div className="container">
                    <Pagination pageCount={resources?.totalCount / 9} onChange={(page) => {
                        getData(page)
                    }}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default BlogList;
