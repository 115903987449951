import React from "react";
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";

import Home from "../pages/Home";
import BlogList from "../pages/BlogList";
import BlogDetail from "../pages/BlogDetail";
import SearchList from "../pages/SearchList";
import MobileMenu from "../components/MobileMenu";
import ScrollTop from "../components/ScrollTop";
import {DownloadBar, Menu, MenuFixed} from "../components";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import DisclaimerAndTestimonialsDisclaimer from "../pages/DisclaimerAndTestimonialsDisclaimer";
import About from "../pages/About";
import Contact from "../pages/Contact";

function Routes() {
    return (
        <Router basename="/">
            <ScrollTop/>
            <div className="animate__animated animate__fadeIn" style={{zIndex: 99, position: 'relative'}}>
                <DownloadBar/>
                <MenuFixed/>
                <Menu/>
            </div>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/contact" component={Contact}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                <Route exact path="/terms-of-use" component={TermsAndConditions}/>
                <Route exact path="/disclaimer-and-testimonials-disclaimer"
                       component={DisclaimerAndTestimonialsDisclaimer}/>
                <Route exact path="/resources/search/:keyword" component={SearchList}/>
                <Route exact path="/resources/" component={BlogList}/>
                <Route exact path="/resources/:slug" component={BlogList}/>
                <Route exact path="/resources/:slug/:slug2" component={BlogDetail}/>
            </Switch>
            <MobileMenu/>
        </Router>
    );
}

export default Routes;
