import React, {useEffect} from "react";
import {
    DownloadBar, Footer, Menu, BlogCategories, BlogPostItem, MenuFixed, Icon
} from "../../components";
import './BlogDetail.scss';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import actions from "../../redux/actions";
import moment from 'moment'


const BlogDetail = () => {

    const dispatch = useDispatch();
    const {resource} = useSelector(state => state.Api);
    const {slug2} = useParams();

    useEffect(() => {
        dispatch({type: actions.GET_RESOURCE, payload: {slug: slug2}})
    }, [slug2])

    return (
        <div className="animate__animated animate__fadeIn">
            <BlogCategories/>
            <div className="blog-detail">
                <div className="container">
                    <h1 className="blog-detail__title">{resource?.resource_name}</h1>
                    <div className="blog-detail__date">
                        {moment(resource?.resource_date).format('MMM, D, YYYY')}

                    </div>
                    <div className="blog-detail__head">
                        <div className="blog-detail__head__left">
                            <p>
                                {resource?.resource_list_content}
                            </p>
                            {/**
                            <div className="blog-detail__head__social">
                                <span>Share</span>
                                <a href="https://www.facebook.com" target="_blank">
                                    <Icon icon="facebook"/>
                                </a>
                                <a href="https://www.instagram.com" target="_blank">
                                    <Icon icon="instagram"/>
                                </a>
                                <a href="https://www.twitter.com" target="_blank">
                                    <Icon icon="twitter"/>
                                </a>
                            </div>**/}
                        </div>
                        <div className="blog-detail__head__right">
                            <div className="blog-detail__image">
                                <img src={'https://barwise.app/p/uploads/' + resource?.resource_image} alt={resource?.resource_name}/>
                            </div>
                        </div>
                    </div>
                    <div className="blog-detail__body" dangerouslySetInnerHTML={{__html: resource?.resource_content}}/>
                    {/**
                     <div className="blog-detail__other-posts">
                     <BlogPostItem/>
                     <BlogPostItem/>
                     <BlogPostItem/>
                     </div>**/}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default BlogDetail;
