import React, {useState, useRef, useEffect} from "react";
import './Introduction.scss';

const Introduction = () => {

    const [anim1, setAnim1] = useState(false);
    const [anim2, setAnim2] = useState(false);
    const [anim3, setAnim3] = useState(false);

    const ref1 = useRef(false);
    const ref2 = useRef(false);
    const ref3 = useRef(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const total = window.pageYOffset + window.innerHeight - 100;
        if (total > ref1.current.offsetTop) {
            setAnim1(true);
        }
        if (total > ref2.current.offsetTop) {
            setAnim2(true);
        }
        if (total > ref3.current.offsetTop) {
            setAnim3(true);
        }
    }

    return (<div className="introduction">
        <div className="introduction__container">
            <h1>Study and practice with ease! <br/> <span className="color1">Anywhere,</span> <span
                className="color2">anytime!</span></h1>
            <div className="container">
                <div className="introduction__row">
                    <div className="introduction__row__left" ref={ref1}>
                        {anim1 ?
                            <object type="image/svg+xml" data="images/0001.svg" width={501} height={422}>
                                <img src="images/0001.svg"/>
                            </object> : <img src="images/0001.svg"/>}
                    </div>
                    <div className="introduction__row__right">
                        <h2>Stay on topic,<br/>learn faster!</h2>
                        <p>Choose your category and dig into to the chapters and learn wisely with cross-reference
                            links.</p>
                    </div>
                </div>
                {/**
                 <div className="introduction__row">
                 <div className="introduction__row__left" ref={ref2}>
                 {anim2 ?
                        <object type="image/svg+xml" data="images/0002.svg" width={420} height={440}>
                            <img src="images/0002.svg"/>
                        </object>:<img src="images/0002.svg"/>}
                 </div>
                 <div className="introduction__row__right">
                 <h2>Practice with<br/> flashcards.</h2>
                 <p>Nunc auctor eros nec porttitor finibus. In a gravida nibh, in sollicitudin nisl. Cras tempor
                 sapien a tempor pellentesque. Praesent justo tellus, cursus vel efficitur eu, imperdiet non
                 nibh. </p>
                 </div>
                 </div>**/}
                <div className="introduction__row">
                    <div className="introduction__row__left" ref={ref3}>
                        {anim3 ?
                            <object type="image/svg+xml" data="images/0003.svg" width={470} height={204}>
                                <img src="images/0003.svg"/>
                            </object> : <img src="images/0003.svg"/>}
                    </div>
                    <div className="introduction__row__right">
                        <h2>Take a note,<br/> bookmark and more...</h2>
                        <p>With its powerful note-taking and bookmarking features, BarWise can help you stay organized
                            and on top of your studies like never before.</p>
                        {/**<p>
                            The note-taking feature allows you to easily jot down important information from your
                            readings and lectures, while the bookmarking feature allows you to quickly access the topics
                            you need to review.
                        </p>**/}
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Introduction
