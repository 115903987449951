import axios from "axios";
import {call, put} from "redux-saga/effects";

export function* sagaWorker(url, type, data, action) {
  const res = yield call(http, url, type, data, '');
  yield put({
    type: action,
    payload: res.data
  });
}

export default async function http(endpoint, type, data, headers) {

  return axios({
    method: type,
    url: endpoint,
    data: data,
    timeout: 1000 * 60 * 2,
    headers: {
      "Content-Type": "application/json",
      ...(headers ? headers : {}),
    },
    responseType: 'json'
  })
    .then((res) => res)
    .catch((error) => error);
}
