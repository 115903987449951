import React from "react";
import './Pagination.scss';
import ReactPaginate from 'react-paginate';
import {Icon} from "../index";

const Pagination = ({pageCount, onChange}) => {
    return (<div className="pagination">
        <ReactPaginate
            breakLabel="..."
            nextLabel={<Icon icon="right-arrow"/>}
            onPageChange={(e) => {
                onChange(e.selected + 1)
            }}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={<Icon icon="right-arrow"/>}
            renderOnZeroPageCount={null}
        />
    </div>);
}

export default Pagination
