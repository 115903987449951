import actions from "./actions";

const reducer = (state = {}, action) => {
    switch (action.type) {
        case actions.SET_RESOURCES_CATEGORIES:
            return {...state, resourcesCategories: action.payload};
        case actions.SET_RESOURCES:
            return {...state, resources: action.payload};
        case actions.SET_RESOURCE:
            return {...state, resource: action.payload};
        case actions.SET_HOME_RESOURCES:
            return {...state, homeResources: action.payload};
        case actions.SET_SEARCH:
            return {...state, searchResult: action.payload};
        case actions.SET_DOWNLOAD_MODAL:
            return {...state, downloadModal: action.payload};
        case actions.SET_MOBILE_MENU:
            return {...state, mobileMenu: action.payload};
        case actions.SET_NEWSLETTER:
            return {...state, newsletter: action.payload};
        case actions.SET_CONTACT:
            return {...state, contact: action.payload};
        default:
            return state;
    }
};

export default reducer;
