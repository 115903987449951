import React from "react";
import {
    Footer
} from "../../components";
import './PrivacyPolicy.scss';


const DisclaimerAndTestimonialsDisclaimer = () => {

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="privacy-policy full-text">
                <h1>Disclaimer &amp; Testimonials Disclaimer</h1>

                <p><strong>Last updated March 01, 2022</strong></p>


                <p><strong>WEBSITE DISCLAIMER</strong></p>


                <p>The information provided by Barwise LLC (&ldquo;we,&rdquo; &ldquo;us&rdquo;, or &ldquo;our&rdquo;)
                    on <a href="https://barwise.app/"><u>https://barwise.app</u></a> (the &ldquo;Site&rdquo;) and our
                    mobile application is for general informational purposes only. All information on the Site and our
                    mobile application is provided in good faith, however we make no representation or warranty of any
                    kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or
                    completeness of any information on the Site or the content of our mobile application. UNDER NO
                    CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                    RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON
                    THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR
                    RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p>


                <p><strong>LEGAL DISCLAIMER</strong></p>


                <p>The Site cannot and does not contain any legal advice.&nbsp;</p>


                <p>Barwise mobile application is provided for supplementary purposes only and is not a substitute for a
                    comprehensive bar exam course. However, we believe they are super helpful for studying for the bar
                    exam.</p>


                <p>We do not guarantee that the content of the Barwise mobile application reflects the correct law in
                    every state at any given time. The bar exam is extremely detailed and all bar exam course material
                    contain some mistakes every now and then. But we promise we will do our best to correct them as soon
                    as we become aware of them.&nbsp;</p>


                <p><strong>TESTIMONIALS DISCLAIMER</strong></p>


                <p>The Site may contain testimonials by users of our products and/or services. These testimonials
                    reflect the real-life experiences and opinions of such users. However, the experiences are personal
                    to those particular users, and may not necessarily be representative of all users of our products
                    and/or services. We do not claim, and you should not assume, that all users will have the same
                    experiences. YOUR INDIVIDUAL RESULTS MAY VARY.&nbsp;</p>


                <p>The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are
                    reviewed by us before being posted. They appear on the Site verbatim as given by the users, except
                    for the correction of grammar or typing errors. Some testimonials may have been shortened for the
                    sake of brevity where the full testimonial contained extraneous information not relevant to the
                    general public.</p>


                <p>The views and opinions contained in the testimonials belong solely to the individual user and do not
                    reflect our views and opinions. We are not affiliated with users who provide testimonials, and users
                    are not paid or otherwise compensated for their testimonials.</p>

                <p><br/>
                    &nbsp;</p>


            </div>

            <Footer/>
        </div>
    );
};

export default DisclaimerAndTestimonialsDisclaimer;
