const path = 'https://barwise.app/p/webapi/';

export default {
    'resourcesCategories': path + 'get_resources_categories',
    'resources': path + 'get_resources',
    'resource': path + 'get_resource',
    'homeResources': path + 'get_home_resources',
    'search': path + 'search',
    'newsletter': path + 'newsletter',
    'contact': path + 'contact'
}
