import React from "react";
import './Menu.scss';
import {DownloadModal, Icon, Search} from "../index";
import {Link, NavLink} from "react-router-dom";
import {useLocation} from 'react-router-dom'
import actions from "../../redux/actions";
import {useDispatch} from "react-redux";

const Menu = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    return (<div className="menu">

        <DownloadModal visible={false}/>
        <div className="container">
            <Link to="/">
                <img src="./images/bar-wise-logo.png"
                     srcSet="./images/bar-wise-logo.png 1x, ./images/bar-wise-logo@2x.png 2x"/>
            </Link>
            <div className="menu__right">
                <nav>
                    <NavLink to="/" exact activeClassName="active">Discover BarWise</NavLink>
                    <NavLink to="/resources" activeClassName="active">Resources</NavLink>
                </nav>
                {location.pathname.replace('resources') !== location.pathname &&
                <div className="menu__search">
                    <Search/>
                </div>}

                <div className="menu__mobile" onClick={()=>{
                    dispatch({type: actions.SET_MOBILE_MENU, payload: true})
                }}>
                    <Icon icon="burger-menu"/>
                </div>
            </div>
        </div>
    </div>);
}

export default Menu
