import React from "react";
import './Footer.scss';
import {Icon} from "../index";
import Newsletter from "../Newsletter";
import {Link} from "react-router-dom";

const Footer = () => {
    return (<>
        <div className="footer">
            <div className="container">
                <div className="footer__left">
                    <div className="footer__mobile__newsletter">
                        <Newsletter/>
                    </div>
                    <div className="footer__links">
                        <Link to="/about">About BarWise</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                    <div className="footer__socials">
                        <a href="https://www.facebook.com/profile.php?id=100090173954801" target="_blank">
                            <Icon icon="facebook"/>
                        </a>
                        <a href="https://www.instagram.com/BarWiseLLC/" target="_blank">
                            <Icon icon="instagram"/>
                        </a>
                        <a href="https://twitter.com/BarwiseLlc" target="_blank">
                            <Icon icon="twitter"/>
                        </a>
                    </div>
                </div>
                <div className="footer__right">
                    <div className="footer__links">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-of-use">Terms of Use</Link>
                    </div>
                    <div className="footer__copyright">
                        BarWise © 2021 All rights reserved.
                    </div>
                </div>
            </div>
            <div className="footer__newsletter">
                <Newsletter/>
            </div>
        </div>
    </>);
}

export default Footer
