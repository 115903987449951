import React from "react";
import {
    Banner,
    Footer,
    HomeBlog,
    Introduction
} from "../../components";


const Home = () => {

    return (
        <div className="animate__animated animate__fadeIn">
            <Banner/>
            <Introduction/>
            <HomeBlog/>
            <Footer/>
        </div>
    );
};

export default Home;
