import {all, takeEvery, put, call} from "redux-saga/effects";
import actions from "./actions";
import http from "./../../utils/http";
import services from "./../services";

export function* SET_RESOURCES_CATEGORIES({payload}) {
    const res = yield call(http, services.resourcesCategories, "post");
    if (res.status === 200) {
        yield put({
            type: actions.SET_RESOURCES_CATEGORIES,
            payload: res.data,
        });
    }
}

export function* SET_RESOURCES({payload}) {
    const res = yield call(http, services.resources, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_RESOURCES,
            payload: res.data,
        });
    }
}

export function* SET_RESOURCE({payload}) {
    const res = yield call(http, services.resource, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_RESOURCE,
            payload: res.data,
        });
    }
}

export function* SET_HOME_RESOURCES({payload}) {
    const res = yield call(http, services.homeResources, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_HOME_RESOURCES,
            payload: res.data,
        });
    }
}

export function* SET_SEARCH({payload}) {
    const res = yield call(http, services.search, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_SEARCH,
            payload: res.data,
        });
    }
}

export function* SET_NEWSLETTER({payload}) {
    const res = yield call(http, services.newsletter, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_NEWSLETTER,
            payload: res.data,
        });
    }
}

export function* SET_CONTACT({payload}) {
    const res = yield call(http, services.contact, "post", {...payload});
    if (res.status === 200) {
        yield put({
            type: actions.SET_CONTACT,
            payload: res.data,
        });
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_RESOURCES_CATEGORIES, SET_RESOURCES_CATEGORIES),
        takeEvery(actions.GET_RESOURCES, SET_RESOURCES),
        takeEvery(actions.GET_RESOURCE, SET_RESOURCE),
        takeEvery(actions.GET_HOME_RESOURCES, SET_HOME_RESOURCES),
        takeEvery(actions.GET_NEWSLETTER, SET_NEWSLETTER),
        takeEvery(actions.GET_SEARCH, SET_SEARCH),
        takeEvery(actions.GET_CONTACT, SET_CONTACT),
    ]);
}
