const actions = {
    GET_RESOURCES_CATEGORIES: "GET_RESOURCES_CATEGORIES",
    SET_RESOURCES_CATEGORIES: "SET_RESOURCES_CATEGORIES",
    GET_RESOURCES: "GET_RESOURCES",
    SET_RESOURCES: "SET_RESOURCES",
    GET_RESOURCE: "GET_RESOURCE",
    SET_RESOURCE: "SET_RESOURCE",
    GET_HOME_RESOURCES: "GET_HOME_RESOURCES",
    SET_HOME_RESOURCES: "SET_HOME_RESOURCES",
    GET_SEARCH: "GET_SEARCH",
    SET_SEARCH: "SET_SEARCH",
    SET_DOWNLOAD_MODAL: "SET_DOWNLOAD_MODAL",
    SET_MOBILE_MENU: "SET_MOBILE_MENU",
    SET_NEWSLETTER: "SET_NEWSLETTER",
    GET_NEWSLETTER: "GET_NEWSLETTER",
    SET_CONTACT: "SET_CONTACT",
    GET_CONTACT: "GET_CONTACT"
};

export default actions;
