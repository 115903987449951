import React, {useEffect} from "react";
import './BlogCategories.scss';
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/actions";
import {Icon} from "../index";
import {Link, useParams} from "react-router-dom";
import classNames from "classnames";

const BlogCategories = () => {
    const dispatch = useDispatch();
    const {resourcesCategories} = useSelector(state => state.Api);
    const {slug} = useParams();

    useEffect(() => {
        dispatch({type: actions.GET_RESOURCES_CATEGORIES})
    }, [])

    return (<div className="blog-categories" id="blog-categories">
        <div className="container">
            <Icon/>
            <Link to="/resources" className={classNames({'active': !slug})}>All</Link>
            {resourcesCategories && resourcesCategories.map((item, index) => {
                return <Link to={'/resources/'+item.resource_category_slug} key={index}
                          className={classNames({'active': slug === item.resource_category_slug})}>{item.resource_category_name}</Link>
            })}
        </div>
    </div>);
}

export default BlogCategories
