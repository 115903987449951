import React from "react";
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";

// Css
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "./assets/css/_styles.scss";

import Routes from "./routes";

import Sagas from "./redux/sagas";
import reducer from "./redux/reducers";
import {DownloadModal} from "./components";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducer,
    applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(Sagas);

function App() {

    return (
        <>
            <Provider store={store}>
                <Routes/>
                <DownloadModal/>
            </Provider>
        </>
    );
}

export default App;
