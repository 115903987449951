import React, {useEffect, useState} from "react";
import {
    Footer, Icon
} from "../../components";
import './Contact.scss';
import {useForm} from "react-hook-form";
import classNames from "classnames";
import actions from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const Contact = () => {
    const {contact} = useSelector(state => state.Api);
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    const onSubmit = (data) => {
        dispatch({type: actions.GET_CONTACT, payload: data})
    }

    useEffect(() => {
        setSuccess(contact?.status)
    }, [contact])

    const onReset = () => {
        setSuccess(false);
        reset();
    }

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="contact">
                <div className="container">
                    <div className="contact__left">
                        <div>
                            <h1>
                                Contact us. <br/>We’re here to help!
                            </h1>
                            {/**
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non eleifend magna, vel
                                cursus nisi. Nulla tempus nibh eget quam feugiat, in auctor sapien bibendum. Ut et lacus
                                maximus, dignissim elit ac, posuere lacus.
                            </p>
 **/}
                        </div>
                        <div className="address">
                            <img src="./images/favicon.png"/>
                            <p>
                                Barwise LLC<br/>
                                3790 El Camino Real Ste1082 Palo Alto CA 94306 USA
                            </p>
                        </div>
                    </div>
                    <div className="contact__right">
                        <img src="./images/contact-badges.png" className="contact__badges"/>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {success &&
                            <div className="success-msg">
                                <div className="success-msg__center">
                                    <Icon icon="contact"/>
                                    <b>Thank you!<br/>
                                        We’ll respond shortly.</b>
                                </div>
                                <div className="success-msg__back" onClick={onReset}>
                                    <Icon icon="right-arrow"/> Send another message
                                </div>
                            </div>}
                            <input type="text" placeholder="Name"
                                   className={classNames({'error': errors.name})} {...register("name", {
                                required: true,
                                minLength: 2
                            })} />
                            <input
                                type="text"
                                placeholder="Email Address"
                                className={classNames({'error': errors.email})}
                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    }
                                })}
                            />
                            <textarea
                                placeholder="Message"
                                className={classNames({'error': errors.message})}
                                {...register("message", {
                                    required: true,
                                    minLength: 5
                                })} />
                            <button type="submit">Send <Icon icon="right-arrow"/></button>

                            {Object.keys(errors).length > 0 &&
                            <div className="error-msg">
                                <Icon icon=""/> Please check again!
                            </div>}
                        </form>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Contact;
