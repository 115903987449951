import React, {useEffect, useState} from "react";
import './Newsletter.scss';
import {Icon} from "../index";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/actions";

const Newsletter = () => {
    const {newsletter} = useSelector(state => state.Api);
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const onSend = (email) => {
        setError(false);
        if (emailIsValid(email)) {
            dispatch({type: actions.GET_NEWSLETTER, payload: {email: email}})
            setValue('');
        } else {
            setError(true);
        }
    }

    return (<div className="newsletter">
        <div className="container">

            <div className="newsletter__text">
                Subscribe and get tips and future updates.
            </div>

            <div className={classNames('newsletter__input', {'newsletter__input--error': error})}>
                {!value && <div className="newsletter__input__placeholder">Type your e mail address</div>}
                <input type="text" value={value} onChange={(e) => {
                    setValue(e.target.value)
                    if (!e.target.value || emailIsValid(e.target.value)) {
                        setError(false)
                    }
                }}
                       onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                               onSend(value);
                           }
                       }}
                />

                <Icon icon="right-arrow" onClick={() => {
                    onSend(value)
                }}/>
            </div>

            <div className="newsletter__info">
                {newsletter && (newsletter?.success  ? <>Email Saved</> : <>Email Available</>)}
            </div>
        </div>
    </div>);
}

export default Newsletter
