import React, {useEffect, useState} from "react";
import './MenuFixed.scss';
import classNames from "classnames";
import {Link, NavLink} from "react-router-dom";
import actions from "../../redux/actions";
import {useDispatch} from "react-redux";
import {Icon} from "../index";

const MenuFixed = () => {
    const dispatch = useDispatch();
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        setVisibility((window.scrollY > 178));
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const handleScroll = () => {
        setVisibility((window.scrollY > 178));
    }

    return (<div className={classNames('menu-fixed', {'active': visibility})}>
        <div className="container">
            <div className="menu-fixed__left">
                <Link to="/" className="logo">
                    <img src="./images/bar-wise-logo.png"
                         srcSet="./images/bar-wise-logo.png 1x, ./images/bar-wise-logo@2x.png 2x"/>
                </Link>
                <nav>
                    <NavLink to="/" exact activeClassName="active">Discover BarWise</NavLink>
                    <NavLink to="/resources" activeClassName="active">Resources</NavLink>
                </nav>
            </div>
            <div className="menu-fixed__download" onClick={()=>{
                dispatch({type: actions.SET_DOWNLOAD_MODAL, payload:true})
            }}>
                Download the app
            </div>

            <div className="menu-fixed__mobile" onClick={()=>{
                dispatch({type: actions.SET_MOBILE_MENU, payload: true})
            }}>
                <Icon icon="burger-menu"/>
            </div>
        </div>
    </div>);
}

export default MenuFixed
