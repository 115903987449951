import React, {useEffect} from "react";
import './HomeBlog.scss';
import {BlogPostItem, Icon} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import actions from "../../redux/actions";

const HomeBlog = () => {

    const dispatch = useDispatch();
    const {homeResources} = useSelector(state => state.Api);

    useEffect(() => {
        dispatch({type: actions.GET_HOME_RESOURCES})
    }, [])

    return (<div className="home-blog">
        <h2>
            Check our regularly-updated blog<br/> for pro tips and insights…
        </h2>
        <div className="container">
            {homeResources && <>
                <div className="home-blog__posts">
                    <BlogPostItem size="large" data={homeResources[0]}/>
                    <BlogPostItem size="small" data={homeResources[1]}/>
                </div>
                <div className="home-blog__posts-other">
                    <BlogPostItem noImg={true} data={homeResources[2]}/>
                    <BlogPostItem noImg={true} data={homeResources[3]}/>
                    <div className="home-blog__resources">
                        <Link to="/resources">
                            Resources
                        </Link>
                    </div>
                </div>
            </>}
        </div>
    </div>);
}

export default HomeBlog
