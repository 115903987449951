import React, {useEffect, useState} from "react";
import './DownloadModal.scss';
import {Icon} from "../index";
import classNames from "classnames";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/actions";
import MobileDetect from "mobile-detect";

const md = new MobileDetect(window.navigator.userAgent);

const DownloadModal = () => {
    const dispatch = useDispatch();

    const {downloadModal} = useSelector(state => state.Api);

    const [modalVisible, setModalVisible] = useState(false);

    const urls = {
        'iOS': 'https://apps.apple.com/gb/app/barwise/id1598137936',
        'AndroidOS': 'https://play.google.com/store/apps/details?id=com.barwiseapp',
        'iPadOS': 'https://apps.apple.com/gb/app/barwise/id1598137936',
    }

    useEffect(() => {
        if (downloadModal) {
            if (urls[md.os()]) {
                window.open(urls[md.os()], '_blank');
            } else {
                setModalVisible(downloadModal);
            }
        } else {
            setModalVisible(false);
        }
    }, [downloadModal]);


    return (<div className={classNames('download-modal', {'download-modal--visible': modalVisible})}>
        <div className="download-modal__back" onClick={() => {
            dispatch({type: actions.SET_DOWNLOAD_MODAL, payload: false})
        }}/>
        <div className="download-modal__content">
            <div className="store">
                <div className="store__icon">
                    <Icon icon="apple"/>
                </div>
                <div className="store__title">
                    Get it on<br/> Google Play
                </div>
                <div className="store__text">
                    Scan this QR code with your<br/> Android Phone and get the app
                </div>
                <div className="store__qr">
                    <img src="./images/android-qrcode.png"/>
                </div>
                <a className="store__link" href={urls['AndroidOS']}>
                    <img src="./images/play-store.png"
                         srcSet="./images/play-store.png 1x, ./images/play-store@2x.png 2x"/>
                </a>
            </div>
            <div className="store">
                <div className="store__icon">
                    <Icon icon="android"/>
                </div>
                <div className="store__title">
                    Download on<br/> the App Store
                </div>
                <div className="store__text">
                    Scan this QR code with your iPhone<br/> and start downloading the app
                </div>
                <div className="store__qr">
                    <img src="./images/ios-qrcode.png"/>
                </div>
                <a className="store__link" href={urls['iOS']}>
                    <img src="./images/app-store.png" srcSet="./images/app-store.png 1x, ./images/app-store@2x.png 2x"/>
                </a>
            </div>
        </div>
    </div>);
}
DownloadModal.propTypes = {
    visible: PropTypes.bool
};
DownloadModal.defaultProps = {
    visible: false,
};
export default DownloadModal
