import React from "react";
import {
    Footer
} from "../../components";
import './About.scss';
import {Link} from "react-router-dom";


const About = () => {

    return (
        <div className="animate__animated animate__fadeIn">

            <div className="about">
                <div className="container">
                    <div className="about__left">
                        <img src="./images/about.png" srcSet="./images/about.png 1x, ./images/about@2x.png 2x"/>
                    </div>

                    <div className="about__right">
                        <div>
                            <h1>Welcome to Barwise!</h1>
                            <p>
                                Welcome to Barwise, the ultimate bar exam preparation app that will help you pass the
                                Multistate Bar Exam (MBE) with confidence!
                            </p>
                            <p>
                                BarWise is designed to assist you in reviewing and retaining a vast amount of
                                information required to excel in the bar exam. Barwise covers all subjects tested on the
                                MBE, so you can be confident that you're well prepared.
                            </p>
                            <p>
                                BarWise team comprises experienced attorneys and legal professionals who have
                                successfully passed the bar exam, so we understand the importance of comprehensive
                                preparation.
                            </p>
                            <p>
                                SOME AMAZING FEATURES FROM BARWISE:<br/>
                                SMART BROWSING: BarWise has a smartly-designed interface that allows you to navigate
                                seamlessly through MBE topics. Check it out for yourself!
                                CROSSLINKED CONTENT: BarWise’s crosslinked content ensures you take a look at the bar
                                exam subjects from a broader perspective, improving your skill to call different areas
                                of law for a better legal analysis.
                                SEARCH: With Barwise, you can easily find any exam subject within seconds. No more
                                wasting time flipping through textbooks and print-outs!
                                MAKE NOTES: Customize your study notes, create your own mnemonics, and add reminders to
                                ensure you memorize essential concepts.
                                PRACTICE SECTION: A practice Q&A section is coming soon and free for all paid
                                subscribers.
                            </p>
                            <p>
                                We are confident that Barwise will be a valuable resource for you as you work towards
                                becoming a licensed attorney.
                            </p>
                            <p>
                                Download our app today and take the first step towards passing the bar exam with ease!
                            </p>
                            <p>
                                With Barwise, you're in good hands!
                            </p>
                            <Link to="/contact" className="contact-link">
                                contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default About;
