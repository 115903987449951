import React, {useState} from "react";
import './Search.scss';
import {Icon} from "../index";
import {useHistory} from "react-router-dom";

const Search = () => {
    const [keyword, setKeyword] = useState('');

    const history = useHistory();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearch();
        }
    }

    const onSearch = () => {
        history.push("/resources/search/" + keyword);
    }

    return (<div className="search">
        <input type="text" placeholder="Search Tips" onKeyDown={handleKeyDown} onChange={(e) => {
            setKeyword(e.target.value)
        }}/>
        <Icon icon="search" onClick={() => {
            onSearch();
        }}/>
    </div>);
}

export default Search
