import React from "react";
import './Banner.scss';
import actions from "../../redux/actions";
import {useDispatch} from "react-redux";
const Banner = () => {
    const dispatch = useDispatch();
    return (<div className="banner">
        <div className="container">
            <div className="banner__left">
                <h1>Pass the bar exam with BarWise!</h1>
                <p>
                    BarWise is a smartly-designed bar exam study tool that helps you prepare for the challenging bar
                    exam ANYWHERE, ANYTIME!</p>
                {/**<p><b>Don't just take our word for it, try it out for yourself and see how BarWise can take your bar
                    exam
                    preparation to the next level!</b>
                </p>**/}
                <div className="banner__download" onClick={() => {
                    dispatch({type: actions.SET_DOWNLOAD_MODAL, payload: true})
                }}>Download the app
                </div>
            </div>
            <div className="banner__right">
                <video controls={false} autoPlay={true} muted>
                    <source src="barwise.mp4" type="video/mp4"/>
                </video>
            </div>
        </div>
    </div>);
}

export default Banner
