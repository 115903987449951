import React, {useState} from "react";
import './DownloadBar.scss';
import actions from "../../redux/actions";
import {useDispatch} from "react-redux";

const DownloadBar = () => {
    const dispatch = useDispatch();


    return (<div className="download-bar">
        <div className="container">
            <div className="download-bar__title">
                Study & practice for bar exam anywhere, anytime!
                <img src="./images/doodle.png" srcSet="./images/doodle.png 1x, ./images/doodle@2x.png 2x"/>
            </div>
            <div className="download-bar__download" onClick={()=>{
                dispatch({type: actions.SET_DOWNLOAD_MODAL, payload:true})
            }}>
                Download the app
            </div>
        </div>
    </div>);
}

export default DownloadBar
